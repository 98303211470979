import type { GatsbyBrowser } from "gatsby"

// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"

// custom CSS styles
import "./src/css/style.css"

console.log('GATSBY BROWSER');

export function onRouteUpdate() {
  if (document.querySelector('.instagram-media') !== null) {
    // Wait to ensure page is rendered first.
    setTimeout(() => {
      if (
        typeof gatsbyLoadInstagram !== `undefined` &&
        typeof window.gatsbyLoadInstagram === `function`
        ) {
        window.gatsbyLoadInstagram()
        if (window.instgrm) {
          window.instgrm.Embeds.process();
        }
      }
    }, 0);
  }
};