exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-blog-post-archive-tsx": () => import("./../../../src/templates/blog-post-archive.tsx" /* webpackChunkName: "component---src-templates-blog-post-archive-tsx" */),
  "component---src-templates-blog-post-tsx": () => import("./../../../src/templates/blog-post.tsx" /* webpackChunkName: "component---src-templates-blog-post-tsx" */),
  "component---src-templates-home-page-tsx": () => import("./../../../src/templates/homePage.tsx" /* webpackChunkName: "component---src-templates-home-page-tsx" */),
  "component---src-templates-page-tsx": () => import("./../../../src/templates/page.tsx" /* webpackChunkName: "component---src-templates-page-tsx" */),
  "component---src-templates-project-post-tsx": () => import("./../../../src/templates/project-post.tsx" /* webpackChunkName: "component---src-templates-project-post-tsx" */),
  "component---src-templates-tag-archive-tsx": () => import("./../../../src/templates/tag-archive.tsx" /* webpackChunkName: "component---src-templates-tag-archive-tsx" */),
  "component---src-templates-work-project-archive-tsx": () => import("./../../../src/templates/work-project-archive.tsx" /* webpackChunkName: "component---src-templates-work-project-archive-tsx" */)
}

